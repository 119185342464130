import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nurturing from "../components/Nurturing"
import { Link } from "gatsby"
import Umbrella from "../components/Umbrella"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"

const NurturingSchoolsPage = () => (
  <Layout>
    <SEO title="Nurturing Schools" />
    <Container className="my-4">
      <Row>
        <Col xs={{ span: 10, offset: 1}}  lg={{ span: 6, offset: 3}}>
          <Nurturing/>
        </Col>
      </Row>
      <h3 className="text-secondary">What is 'Nurturing Schools with Waite Psychology'?</h3>
        <p className="lead">
          'Nurturing Schools with Waite Psychology' forms the umbrella under which our specialism in social, emotional and mental health is delivered.
        </p>
      <p>
        Dr. Jennifer Waite has spent the last 15 years (stemming back into her undergraduate years) involved, in one capacity or another, within nurture groups. This has extended far and wide within her applied psychology practice as an Attachment Aware and Trauma Informed (AATI) specialist.
      </p>
      <p>
        Dr. Michael Waite, albeit newer to educational psychology, has wide-reaching knowledge and expertise within the field of social and emotional development. This is inclusive of applications of AATI practice in schools and the implementation of emotion coaching. Having also been a researcher on the emotional, psychological and social wellbeing of secondary aged pupils, Michael draws upon a deeper application of psychology within adolescent development; utilising a school's use of assessments for intervention and applications of the Human Givens approach.
      </p>
      <p>
        Together, Jennifer and Michael combine their expertise and offer a range of creative solutions for schools and pupils with social, emotional and mental health needs. They also dedicate time within their continual professional development and annual service development plan for their specialism. This enables ongoing development of the support they provide and the application of current psychological knowledge, advice and evidence-based interventions, leading to better outcomes for children and young people.
      </p>
      <Row>
        <Col xl={{span: 3, offset: 0}} lg={{span: 4, offset: 0}} md={{span: 4, offset: 4}} xs={{span: 10, offset: 1}} className="pr-lg-5">
          <Umbrella/>
        </Col>
        <Col xl={9} lg={8}>
          <h3 className="text-primary">Services for Schools</h3>
          <p>
            The support provided under the umbrella of 'Nurturing Schools with Waite Psychology' is identical to and incorporated within our general services for schools. This is inclusive of support for individual children and young people presenting with social, emotional and/or mental health needs; staff training; and/or consultancy and project work. The packages and costs, therefore, remain consistent with all other services we provide (please see <Link to="/packages">Packages/Costs</Link>).
          </p>
        </Col>
      </Row>
      <Card border="secondary" className="my-5">
        <Card.Header className="bg-secondary text-white">
          <h4>At the individual level, examples include:</h4>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>Consultation-based action plans for children and young people presenting with complex social, emotional and mental health needs, that formulate part of the 'assess, plan, do, review' process.</ListGroup.Item>
          <ListGroup.Item>Comprehensive psychological assessments, advice and reports where the graduated approach is being implemented and additional provisions are being sought via a request for an education health and care needs assessment.</ListGroup.Item>
          <ListGroup.Item>Emergency consultation-based action planning for children and young people at risk of permanent school exclusion, where reviews of the action plan occur weekly (via telephone consultation) for the first month after initial implementation.</ListGroup.Item>
        </ListGroup>
      </Card>
      <Card border="primary" className="mb-5">
        <Card.Header className="bg-primary text-white">
          <h4>For staff training, examples include:</h4>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>The three day 'Nurturing Settings' training, which facilitates the setting up and running of a nurture group.</ListGroup.Item>
          <ListGroup.Item>Becoming an 'Attachment Aware and Trauma Responsive' school.</ListGroup.Item>
          <ListGroup.Item>How to become a 'Sanction Free and Reward Free' classroom.</ListGroup.Item>
          <ListGroup.Item>Assessment for intervention: what assessment and monitoring tools can schools use?</ListGroup.Item>
          <ListGroup.Item>Emotion coaching: theory and practice.</ListGroup.Item>
          <ListGroup.Item>How to aid emotional and social development in the early years: the power of language!</ListGroup.Item>
          <ListGroup.Item>The importance of play for social and emotional development.</ListGroup.Item>
        </ListGroup>
      </Card>
      <Card border="secondary" className="mb-5">
        <Card.Header className="bg-secondary text-white">
          <h4>For consultancy and project work, examples include:</h4>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>Direct support to set up and run a nurture group, inclusive of whole school training on various aspects of AATI practice and nurture groups; support to develop an emotional regulation policy; direct involvement in the development of the room; advice on the use of assessments and monitoring tools; support through panel meetings and reintegration plans; and support to develop a nurturing curriculum.</ListGroup.Item>
          <ListGroup.Item>Developing and disseminating an emotional regulation and relationship policy underpinned by an 'Attachment Aware and Trauma Responsive' approach.</ListGroup.Item>
          <ListGroup.Item>Supporting newly-qualified teachers in 'tricky classes' to find effective classroom management approaches, which aid practitioner confidence and children's emotional wellbeing.</ListGroup.Item>
          <ListGroup.Item>Using focus groups to establish pupil voice on school wellbeing, and identifying key themes through thematic analysis to inform school policy.</ListGroup.Item>
          <ListGroup.Item>Coordinating local nurture forums.</ListGroup.Item>
          <ListGroup.Item>Supporting a research project between Lancaster University and The Nurture Group Network (Hibbin, Rebecca and Warin, Jo (2016) <span className="font-italic">Nurture groups in practice: children; classes; schools: Final report of Comparative study of nurture groups and alternative provisions for children with social, emotional and behavioural difficulties.</span> Lancaster University).</ListGroup.Item>
        </ListGroup>
      </Card>
    </Container>
  </Layout>
)

export default NurturingSchoolsPage
